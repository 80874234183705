(function () {
  $('.burger-menu').on('click', function(){
    $('.main-nav').addClass('active');
    $('.main-header__logo').addClass('active-menu');
    $('body').addClass('lock')
  })
  $('.main-nav__close').on('click', function(){
    $('.main-nav').removeClass('active');
    $('.main-header__logo').removeClass('active-menu');
    $('body').removeClass('lock');
    
  })
})();