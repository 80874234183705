(function () {
  $(".hero-slider").each(function () {
    let $this = $(this);
    $this.slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
    });
  });
  $(".reviews__list").each(function () {
    let $this = $(this);
    $this.slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
    });
  });
})();
